import axios from "axios";

const api = axios.create({
  baseURL: "https://lucin2-api.salesbreath.com.br/api/v1",
  //baseURL: "http://lucin2homologacao.salesbreath.com.br:3010/api/v1/",
 // baseURL: "http://localhost:3013/api/v1/",
  headers: { "Content-Type": "application/json" }
});

export default api;
